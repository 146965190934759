const locale = {
    "TITLE": {
        "LIVE":"直播",
        "PLAYBACK":"回放",
        "ERROR": "错误",
        "INFO": "提示",
        "OK":"确定",
        "CANCEL":"取消",
        "ADMIN":"管理员",
        "SIGN-IN":"登 录",
        'GREETING':"您好",
        'GO-BACK':"返回",
        'SUBMIT':"提交",
        "SIGN-OUT":"登出系统",
        "SPEAKER":"主讲人",
        "TIME":"时间",
        "DESCRIPTION":"简介",
        "ENTER-LIVE":"进入直播",
        'COMMENTS':'评论',
        'INPUTS':"我的评论",
        "SEND":"提交评论",
        "SOURCE":"流站点",
        "SHANGHAI":"上海",
        "HANGZHOU":"杭州",
        "BEIJING":"北京",
        "LANGUAGE":"语言",
        "PRIVATE-QUESTION":"这是私密评论",
        "ANNOUNCE":"公告",
        "LATEST-EVENTS":"最近的活动",
        "SEARCH":"搜索",
        "LIVE-EVENT":"直播节目",
        "PLAY-BACK":"回播录像",
        "MORE":"更多...",
        "HOT-EVENTS":"热播榜",
        "WELCOME":"欢迎访问",
        "MORE-EVENTS":"更多节目",
        "SSO":"统一身份",
        "LOCAL":"本地登录",
        "SIGN-IN-TO-COMMENT":"登录后发送评论",
        "SUBJECT":"主题",
        "DATE":"日期",
        "SECTION":'栏目',
        "1MONTH":"一个月",
        "TODAY":"今日",
        "6MONTH":"半年",
        "1YEAR":"一年",
        "3YEAR":"三年",
        "RANGE":"范围",
        "UNAUTHORIZED":"你无权访问此节目",
        "FILTER":"筛选",
        "SELECT-SECTION":'选择栏目',
        "SELECT-FILTER":"选择筛选条件",
        "NOT-SELECTED":"未选择",
        "SELECT-DATE-RANGE":"时间范围",
        "CONFIRM":"确定",
        "SELECT-SECTION-DATE":"选择栏目和日期",
        "NO-MORE":"到底了...",
        "BACK":"返回",
        "NETID":"上财 NetID 登录",
        "SSO-LOGIN":"统一身份登录",
        "CLOSE":"关闭窗口",
        "SUGGEST-EVENTS":"推荐节目",
        "DETAILS":"详细内容",
        "QRCODE":"扫描二维码",
        "APPLICATION":"直播申请",

    },
    "MESSAGE": {
        "NO-RESPONSE": '您由于长时间未响应，系统自动登出',
        "CONNECT-FAILURE":"网络故障，请稍后再试!",
        "WAIT":"请稍等 ...",
        "GO-SSO":"SSO登录页面",
        "CLICK-SSO":"点击转去<b>SSO</b>页面",
        "RECOMMEND":"*推荐使用 Chrome,Safari,Firefox & Edge 浏览器*",
        "UNAUTHORIZED":"您无权访问此页面，请点击返回!",
        'NOT-FOUND':"无法找到此节目!",
        'NOT-INVITED':"您不在此节目观看列表中!",
        'INTERNAL-ONLY':'仅校内可以访问',
        "INPUT-PASSCODE":"请输入观看码",
        "USERID-PASSWORD":"账号和密码不能为空",
        "CONFIRM-QUIT":"将要退出系统, 是否继续?",
        'ON-LIVE':"直播已开始",
        'EVENT-START':'离直播开始还剩',
        "EVENT-CLOSED":'直播已经结束。',
        "DVR-NOT-READY":"很抱歉，录像文件还未生成，请稍后再查看。",
        "WATCH-DVR":"请看回放",
        "COMMENT-SENT":"评论已经发送，请等待回复。",
        "NO-DATA":"暂无节目",
        "WELCOME":"欢迎来到直播平台的登录界面，如果您是校内用户，" +
            "请点击SSO按钮跳转到学校的统一身份登录网站进行登录。如是校外用户，需要管理员为您开通账户。",
        "SSO-ERROR":"错误的SSO返回信息,重新登录",
        "QUESTION":"我要提问...",
        "NO-COMMENTS":"暂无评论...",
        "NOT-ALLOWED":"您无权访问此页面",

    },

    "MENU":{
        "HOME":"主页",
        "LIVE":"直播",
        "PLAYBACK":"回放",
        "CHANNEL":"频道",
        "ADMIN":"后台",
        "APPLICATION":"申请"
    },
    "ERROR":{
        "UNAUTHORIZED": "您无权访问该页面!"
    },
    "APPLICATION":{
        "LIVE-APPLICATION":"直播申请",
        "MY-APPLICATION":"我的申请",
        "HISTORY":"历史记录",
        "NEW":"新建申请",
    }
}

export default {
    ...locale
};
