import Vue from 'vue'
import Router from 'vue-router'
import Home from './home'
import org from '../config/organization'

Vue.use(Router)

function mobile() {
    let mobile =    !!(navigator.userAgent.match(
        /(MicroMessenger|phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i));

    if (window.innerWidth > 980) mobile = false;  //超级宽的就不需要mobile 模式
    if (navigator.userAgent.match(/TaskCenterApp/i)) return mobile = true;

    if (mobile) {
        document.body.style.overflow = "hidden";
    } else {
        document.body.style.overflow = "auto";
    }

    return mobile?"mobile/":"web/";
}
/*** hack Error: Redirected when going from “/login“ to “/home“ via a navigation guard.***/
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        ...Home,
        {
            path: '/go-sso',
            name: 'go-sso',
            meta: { public: true },
            component: () => import(/* webpackChunkName: "sign-in" */  '../views/go-sso')
        },
        {
            path: '/sign-in',
            name: 'sign-in',
            meta: { public: true },
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            // 只有将写成这样才能够识别出来，否则报module没有发现
            component: () => import(/* webpackChunkName: "sign-in" */  '../views/' + mobile() + org.template_prefix + "sign-in" )
        },
        {
            path: '/privacy',
            name: 'privacy',
            meta: { public: true },
            component: () => import(/* webpackChunkName: "prohibit" */  '../views/privacy')
        },
        {
            path: '/prohibit',
            name: 'prohibit',
            meta: { public: true },
            component: () => import(/* webpackChunkName: "prohibit" */  '../views/' + mobile() + "prohibit")
        },
        {
            path: '/not-found',
            name: 'notfound',
            meta: { public: true },
            component: () => import(/* webpackChunkName: "prohibit" */  '../views/' + mobile() + "notfound")
        },
        {
            path: '/',
            name: 'public',
            component: () => import(/* webpackChunkName: "prohibit" */  '../views/' + mobile() + "public"),
            children: [
                {
                    path: '/portal',
                    icon: 'el-icon-plus',
                    meta: {public: org.public_portal},
                    component: () => import(/* webpackChunkName: "home" */ '../views/' + mobile() + "portal"),
                    name: '主页',
                },
                {
                    path: '/live',
                    icon: 'el-icon-plus',
                    meta: {public: org.public_portal},
                    component: () => import(/* webpackChunkName: "home" */ '../views/' + mobile() + "live"),
                    name: '直播',
                },
                {
                    path: '/playback',
                    icon: 'el-icon-plus',
                    meta: {public: org.public_portal},
                    component: () => import(/* webpackChunkName: "home" */ '../views/' + mobile()  + "playback"),
                    name: '回播',
                },
                {
                    path: '/profile',
                    icon: 'el-icon-plus',
                    meta: {},
                    component: () => import(/* webpackChunkName: "home" */ '../views/' + mobile()  + "profile"),
                    name: '档案',
                },
            ]
        },
        {
            path: '/play/:id',
            name: 'play',
            meta: {sso: org.sso_play,public:!org.sso_play},
            component: () => import(/* webpackChunkName: "host" */ '../views/' + mobile()  + "play"),
        },
        {
            path: '/branch/:id',
            name: 'branch',
            meta: {level:3, sso:true},
            component: () => import(/* webpackChunkName: "host" */ "../views/web/branch"),
        },
        {
            path: '/console/:id',
            name: 'console',
            meta: {public: true},
            component: () => import(/* webpackChunkName: "host" */ '../views/' + mobile() + "console"),
        },
        {
            path: '/echo',
            name: 'echo',
            meta: {public:true},
            component: () => import(/* webpackChunkName: "host" */ '../views/test-echo'),
        },
        {
            path: "/admin-sign-in",
            name:"admin-sign-in", redirect: {path:"/sign-in",query:{admin:1}}, meta: {public: true}
        },
        {
            path: '/application',
            name: 'application',
            meta: { public:false },
            component: () => import(/* webpackChunkName: "host" */ '../views/' + mobile() + 'application'),
        },
        {
            path: '*',
            name: 'default',
            redirect: '/portal',
        }
    ]
})

