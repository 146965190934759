import './element'
import Vue from 'vue'
import axios from 'axios'
import qs from "qs";
import config from "../config/"
import utility from "./utility"

// directives
import '../common/directives/picture-viewer'
import '../common/directives/mobile-dialog'
import '../common/directives/mobile-class'

// others
import ElementUI from 'element-ui'
import 'bootstrap/dist/css/bootstrap.min.css'
import VueLazyLoad from 'vue-lazyload';
import mobileInput from "@/common/mobile-input";
import VueI18n from 'vue-i18n'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import english from '@/language/english'
import chinese from "@/language/chinese"
import VueCookie from "vuecookie";

// or with options
const loadImage = require('@/assets/images/lazy-loading.png');
const errorImage = require('@/assets/images/lazy-error.png')
const mobile = ()=> mobileInput() !== '';

Vue.use(VueLazyLoad, {
    preLoad: 1.3,
    error: errorImage,
    loading: loadImage,
    attempt: 1
});
Vue.use(VueCookie);
Vue.use(VueI18n);

Vue.use(utility);
Vue.prototype.$qs = qs;

export const i18n = new VueI18n({
    locale: VueCookie.get('lang') || 'zh-CN',
    messages: {
        'zh-CN': {
            ...zhLocale,
            ...chinese
        },
        'en': {
            ...enLocale,
            ...english
        },
    }
});


Vue.use(ElementUI, {
    i18n: (key, value) => {
        if (key === 'el.table.clearFilter') { // 那个全部显示成默认 hack by parker
            return i18n.locale === 'en' ? 'Default' : '默认'
        }
        return i18n.t(key, value)
    }
})

/**
 * Locale Tool
 */

Vue.prototype.$setLocale = () => {
    i18n.locale = (i18n.locale === 'en' ? 'zh-CN' : 'en');
    VueCookie.set('lang', i18n.locale, {expires: '5Y'}) //后面会用做切换和将用户习惯存储到本地浏览器
}

Vue.prototype.$english = () => i18n.locale === 'en';

/**
 * API Tool
 */

const apiUrl = function (command) {

    const cmd = command.split('?');
    const command_array = cmd[0].split('.');

    let value = config.api.modules;
    for (let i = 0; i < command_array.length; i++) {
        value = value[command_array[i]];
        if (value === undefined) {
            if (i === command_array.length - 1) {
                value = '';
                for (let j = 0; j <= i; j++) {
                    value += '/' + command_array[j].addHyphen();
                }
                break;
            }
            break;
        }
    }

    if (value === undefined) { // 没找到定义的api，那么我自己为此生成一个
        value = "";
        for (let i = 0; i < command_array.length; i++) {
            value += '/' + command_array[i].addHyphen();
        }
        return config.api.url + value;
    } else return config.api.url + value;

}

Vue.prototype.$apiUrl = apiUrl;

Vue.prototype.$apiJson = function (command, data, warning) {
    const that = this;
    const english = this.$english();

    return new Promise(function (resolve, reject) {

        let url = config.api.url;
        let value = url.replace('api/v1', command);
        // 将数组data连接成query字串
        let params = qs.stringify(data);
        that.$store.commit('user/setUserTime');
        let axios_config = {
            headers: {
                'user-token': that.$store.state.user.token,
                'Accept': 'application/json',
                'language': english ? 'en' : 'zh'
            },
            responseType: 'json'
        };
        if (params) value += '?' + params;
        axios.get( value, axios_config)
            .then(function (response) {
                that.$store.commit('process/stop');
                if (warning && !response.data.success) {
                    that.$notice.error(response.data.text, i18n.t('TITLE.ERROR'))
                    reject({response: response, state: 499});
                } else {
                    resolve(response)
                }
            })
            .catch(function (error) {
                that.$store.commit('process/stop');
                console.error(error, 'unknown error:');
                that.$notice.error(i18n.t('MESSAGE.CONNECT-FAILURE'), i18n.t('TITLE.ERROR'));
                reject(error);
            });
    })
}

Vue.prototype.$api = function (command, data, warning, download) {

    const that = this;
    const english = this.$english();
    return new Promise(function (resolve, reject) {
        // check if command is right

        const cmd = command.split('?');
        const params = cmd.length === 2 ? '?' + cmd[1] : '';

        const command_array = cmd[0].split('.');

        let value = config.api.modules;
        for (let i = 0; i < command_array.length; i++) {
            value = value[command_array[i]];
            if (typeof value === 'undefined') {
                if (i === command_array.length - 1) {
                    value = '';
                    for (let j = 0; j <= i; j++) {
                        value += '/' + command_array[j].addHyphen();
                    }
                    break;
                }
                break;
            }
        }

        if (typeof value === 'undefined') {
            value = "";
            for (let i = 0; i < command_array.length; i++) {
                value += '/' + command_array[i].addHyphen();
            }
        }

        const post_data = Object.assign({
            user_token: that.$store.state.user.token,
            language: english ? 'en' : 'zh',
            client_token: that.$store.getters['preset/client_token']
        }, data);

        that.$store.commit('process/start');
        that.$store.commit('user/setUserTime');

        let axios_config = {
            headers: {
                'user-token': that.$store.state.user.token,
                'Accept': 'application/json',
                'language': english ? 'en' : 'zh'
            }
        };
        if (download) axios_config.responseType = "blob";

        axios.post(config.api.url + value + params, qs.stringify(post_data), axios_config)
            .then(function (response) {
                that.$store.commit('process/stop');
                if (download) {
                    resolve(response);
                } else {
                    if (warning && !response.data.success) {
                        that.$notice.error(response.data.text, i18n.t('TITLE.ERROR'))
                        reject({response: response, state: 499});
                    } else {
                        resolve(response)
                    }
                }
            })
            .catch(function (error) {
                that.$store.commit('process/stop');
                if (error && error.response && error.response.data) {
                    if (error.response.status === 401) {
                        that.$alert(i18n.t("MESSAGE.NO-RESPONSE"),
                            i18n.t('TITLE.INFO'), {
                                confirmButtonText: i18n.t('TITLE.OK', english),
                                callback: () => {
                                    that.$store.commit('user/clear');
                                    const to = that.$route;
                                    that.$router.replace({
                                        name: 'sign-in',
                                        query: {_t: encodeURIComponent(to.path), _sso: (to.meta && to.meta.sso) ? 1 : 0}
                                    });
                                }
                            }).then(() => {
                        });
                    } else {
                        let text = '';
                        if (error.response) {
                            console.error('err-response:', error.response);
                            if (error.response.statusText) {
                                text = error.response.statusText;
                            }
                            if (error.response.data && !download) {
                                if (error.response.data.message)
                                    text = error.response.data.message;
                                if (error.response.data.text) {
                                    text = error.response.data.text;
                                }
                            }
                            if (error.response.data && error.response.data.original && !download) {
                                text = error.response.data.original.text;
                            }

                        } else {
                            console.error('err:', error);
                        }
                        that.$notify({
                            title: i18n.t('TITLE.ERROR'),
                            message: text,
                            type: 'error'
                        });
                    }
                } else {
                    console.error(error, 'unknown error:');
                    that.$notice.error(i18n.t('MESSAGE.CONNECT-FAILURE'), i18n.t('TITLE.ERROR'));
                }
                reject(error);
            });
    })
};

Vue.prototype.$messageBox = {
    error: function (message, title, callback) {
        if (!title) title = i18n.t('TITLE.ERROR');
        if (mobile()) {
            Vue.prototype.$dialog({
                title: title,
                lockBgScroll: true,
                content: message,
                noOkBtn: true,
                cancelBtnTxt: i18n.t('TITLE.OK'),
            });
        } else {
            Vue.prototype.$alert(message, title, {
                confirmButtonText: i18n.t('TITLE.OK'),
                dangerouslyUseHTMLString: true,
                type: 'error',
                callback: callback
            })
        }
    },
    success: function (message, title, callback) {
        if (!title) title = i18n.t('TITLE.INFO');
        if (mobile()) {
            Vue.prototype.$dialog({
                title: title,
                lockBgScroll: true,
                content: message,
                noOkBtn: true,
                cancelBtnTxt: i18n.t('TITLE.OK'),
            });
        } else {
            Vue.prototype.$alert(message, title, {
                confirmButtonText: i18n.t('TITLE.OK'),
                dangerouslyUseHTMLString: true,
                type: 'success',
                callback: callback
            });
        }
    }
};


Vue.prototype.$notice = {
    error: function (message, title) {
        if (!title) title = i18n.t('TITLE.ERROR');
        if (mobile()) {
            Vue.prototype.$toast.fail(message);
        } else {
            Vue.prototype.$notify({
                title: title,
                message: message,
                type: 'error'
            });
        }
    },
    success: function (message, title) {
        if (!title) title = i18n.t('TITLE.INFO');
        if (mobile()) {
            Vue.prototype.$toast.success(message);
        } else {
            Vue.prototype.$notify({
                title: title,
                message: message,
                type: 'success'
            });
        }
    },
};

Vue.prototype.$affirm = function (text) {
    const title = i18n.t('TITLE.INFO');
    return Vue.prototype.$confirm(text, title, {
        confirmButtonText: i18n.t('TITLE.OK'),
        cancelButtonText: i18n.t('TITLE.CANCEL'),
        type: 'info'
    });
};

Vue.prototype.$isMobile = mobile;
