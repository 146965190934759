/**
 * Created by plu on 7/15/18.
 */

function utility(Vue) {

    String.prototype.arrayReplace = function (arr, needle) {
        if (!needle) needle = "%s";
        let val = this;
        for (let i = 0; i < arr.length; i++) {
            val = val.replace(needle, arr[i]);
        }
        return val;
    };

    String.prototype.addHyphen = function () {
        const reg = /[A-Z]+[a-z0-9]+/g;
        const find = this.match(reg);
        if (find) return find.join('-').toLowerCase();
        return this.toLowerCase();
    }

    Array.prototype.valueSearch = function (defaultValue, field, exact) {
        if (this.length === 0) return false;
        if (!field) field = "value";
        let index = -1;
        //这里要修改通过defaultValue查找index的位置.
        this.some((item, idx) => {
                if (item.constructor === Object) {
                    //如果是Object的，查value
                    if (item[field] === defaultValue) {
                        index = idx;
                        return true;
                    }
                } else {
                    if (item === defaultValue) {
                        index = idx;
                        return true;
                    }
                }
            }
        )
        if (index === -1) return exact?false:this[0];
        return this[index];
    }

    function jq_throttle(delay, no_trailing, callback, debounce_mode) {
        // After wrapper has stopped being called, this timeout ensures that
        // `callback` is executed at the proper times in `throttle` and `end`
        // debounce modes.
        let timeout_id,

            // Keep track of the last time `callback` was executed.
            last_exec = 0;

        // `no_trailing` defaults to falsy.
        if (typeof no_trailing !== 'boolean') {
            debounce_mode = callback;
            callback = no_trailing;
            no_trailing = undefined;
        }

        // The `wrapper` function encapsulates all of the throttling / debouncing
        // functionality and when executed will limit the rate at which `callback`
        // is executed.
        function wrapper() {
            let that = this,
                elapsed = +new Date() - last_exec,
                args = arguments;

            // Execute `callback` and update the `last_exec` timestamp.
            function exec() {
                last_exec = +new Date();
                callback.apply(that, args);
            }

            // If `debounce_mode` is true (at_begin) this is used to clear the flag
            // to allow future `callback` executions.
            function clear() {
                timeout_id = undefined;
            }

            if (debounce_mode && !timeout_id) {
                // Since `wrapper` is being called for the first time and
                // `debounce_mode` is true (at_begin), execute `callback`.
                exec();
            }

            // Clear any existing timeout.
            timeout_id && clearTimeout(timeout_id);

            if (debounce_mode === undefined && elapsed > delay) {
                // In throttle mode, if `delay` time has been exceeded, execute
                // `callback`.
                exec();

            } else if (no_trailing !== true) {
                // In trailing throttle mode, since `delay` time has not been
                // exceeded, schedule `callback` to execute `delay` ms after most
                // recent execution.
                //
                // If `debounce_mode` is true (at_begin), schedule `clear` to execute
                // after `delay` ms.
                //
                // If `debounce_mode` is false (at end), schedule `callback` to
                // execute after `delay` ms.
                timeout_id = setTimeout(debounce_mode ? clear : exec, debounce_mode === undefined ? delay - elapsed : delay);
            }
        }

        // Return the wrapper function.
        return wrapper;
    }


    // 对Date的扩展，将 Date 转化为指定格式的String
    // 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
    // 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
    // 例子：
    // (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
    // (new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18
    Date.prototype.Format = function (fmt) { //author: meizz
        let o = {
            "M+": this.getMonth() + 1,                 //月份
            "d+": this.getDate(),                    //日
            "h+": this.getHours(),                   //小时
            "m+": this.getMinutes(),                 //分
            "s+": this.getSeconds(),                 //秒
            "q+": Math.floor((this.getMonth() + 3) / 3), //季度
            "S": this.getMilliseconds(),            //毫秒
            "W": "日一二三四五六日".slice(this.getDay(), 1 + this.getDay()),
            "w": this.getDay()
        };
        if (/(y+)/.test(fmt))
            fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (let k in o)
            if (new RegExp("(" + k + ")").test(fmt))
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        return fmt;
    };

    Array.prototype.extend = function (other_array) {
        other_array.forEach(function (v) {
            this.push(v);
        }, this);
    };

    Vue.prototype.$isWechat = function () {
        const ua = navigator.userAgent.toLowerCase();
        return ua.match(/MicroMessenger/i) != null || ua.match(/MacWechat/i) != null || ua.match(/WindowsWechat/i) != null;
    };

    Vue.prototype.$isAndroid = function () {

    };

    Vue.prototype.$is_date = function (string) {
        const dateReg = /^(\d{4})-(\d{2})-(\d{2})$/ig;
        return dateReg.test(string);
    };
    Vue.prototype.$is_time = function (string) {
        const timeReg = /^(\d{2}):(\d{2})[:(\d{2})]*$/ig;
        return timeReg.test(string);
    };
    Vue.prototype.$dateParse = function (string, time) {
        const dateReg = /^(\d){4}-(\d){2}-(\d){2}$/ig;
        const dateTimeReg = /^(\d){4}-(\d){2}-(\d){2} (\d){2}:(\d){2}(:\d{2})?$/;
        let date = new Date();
        if (time) {
            if (dateTimeReg.test(string)) {
                const str = string.replace(/-/g, '/');
                date = new Date(str);
            }
        } else {
            if (dateReg.test(string)) {
                const str = string.replace(/-/g, '/');
                date = new Date(str);
            }
        }
        return date;
    };
    Vue.prototype.$dateTimeParse = function (string) {
        const dateReg = /^(\d){4}-(\d){2}-(\d){2} (\d){2}:(\d){2}(:\d{2})?$/;
        let date = 0;
        if (dateReg.test(string)) {
            const str = string.replace(/-/g, '/');
            date = new Date(str);
            date = date.getTime() / 1000;
        }
        return date;
    };
    Vue.prototype.$getQueryString = function (name) {
        let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        let r = window.location.search.substr(1).match(reg);
        if (r != null) return decodeURI(r[2]);
        return null;
    };

    Vue.prototype.$getSegment = function (index) {
        let r = window.location.pathname.substr(1).split('/');
        if (index) {
            if (r.length < index) {
                return "";
            } else return r[index];
        }
        return r[0];
    };

    Vue.prototype.$uuid = function () {
        return new Date().getTime().toString(24);
    };

    Vue.prototype.$debounce = function (delay, at_begin, callback) {
        return callback === undefined
            ? jq_throttle(delay, at_begin, false)
            : jq_throttle(delay, callback, at_begin !== false);
    };

    Vue.filter("withzero", function (value) {
        return value ? value : 0;
    });

    Vue.prototype.$title = function ($str) {
        document.title=$str;
    };

    Vue.filter("dicimal", function (value) {
        let realVal = Number(value).toFixed(2);
        return Number(realVal)
    });

    Vue.filter('short_time',function(value) {
            const date = new Date(value * 1000);
            return date.Format('hh:mm');
    })

    Vue.prototype.$isMobile = function () {
        return (navigator.userAgent.match(
            /(MicroMessenger|phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))
    }

    Vue.prototype.$urlParse = function (url) {
        const parse_url = /^(?:([A-Za-z]+):)?(\/{0,3})([0-9.\-A-Za-z]+)(?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/;
        const result = parse_url.exec(url);
        const names = ['url', 'scheme', 'slash', 'host', 'port', 'path', 'query', 'hash'];
        let ret = {};
        for (let i = 0, len = names.length; i < len; i += 1) {
            ret[names[i]] = result[i];
        }
        ret['origin'] = ret.scheme + ":" + ret.slash + ret.host + (ret.port ? (":" + ret.port) : "");
        return ret;
    }

    Vue.prototype.$replaceUrlOrigin = function (url) {
        const info = this.$urlParse(url);
        return info.url.replace(info.origin,window.location.origin);
    }

    Vue.prototype.$download = function (data,name) {
        const fileURL = window.URL.createObjectURL(
            data
        );
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", name);
        document.body.appendChild(fileLink);

        fileLink.click();
    }

}

export default {
    install: function (Vue) {
        utility(Vue);
    }
}
