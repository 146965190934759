//判断是否是手机模式，如果是则跳转到mobile目录下，引用module
export default function () {
    const  mobile_disabled = false;

    let mobile = (navigator.userAgent.match(
        /(MicroMessenger|phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))
        ?'mobile/':'';

    if (mobile_disabled || window.innerWidth > 980) return '';  //超级宽的就不需要mobile 模式

    return  mobile;

}

