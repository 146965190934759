export default [
    {
        path: '/',
        name: '',
        icon: 'el-icon-tickets',
        component: () => import(/* webpackChunkName: "home" */ '../views/home'),
        hidden: true,
        children: [
            {path: "", redirect: "/portal"},
            {path: "admin", redirect: "/admin/program"},
            {
                path: '/admin/program/edit/:id?',
                component: () => import(/* webpackChunkName: "profile" */ '../views/edit-program'),
                name: '编辑节目'
            },
        ]
    },
    {
        path: '/',
        name: 'welcome',
        icon: 'el-icon-odometer',
        component: () => import(/* webpackChunkName: "home" */ '../views/home'),
        hidden:true,
        meta: {level: 4,},
        children: [
            {
                path: '/admin/dashboard',
                component: () => import(/* webpackChunkName: "home" */ '../views/dashboard'),
                name: '仪表盘',
                redirect: "/admin/program"
            }
        ]
    },
    {
        path: '/',
        name: '节目管理',
        icon: 'el-icon-video-camera',
        component: () => import(/* webpackChunkName: "home" */ '../views/home'),
        meta: {level: 3},
        children: [
            {
                path: '/admin/program',
                icon: 'el-icon-video-camera-solid',
                component: () => import(/* webpackChunkName: "home" */ '../views/admin-program'),
                name: '节目编辑',
                meta: {friendly: '节目', model: 'Program', preview: true, keepAlive: true}
            },
            {
                path: '/admin/teacher',
                icon: 'el-icon-s-custom',
                component: () => import(/* webpackChunkName: "home" */ '../views/admin-teacher'),
                name: '讲师管理',
                meta: {friendly: '教师', model: 'Teacher', copy: true, keepAlive: false, preview: true}
            },
            {
                path: '/admin/section',
                icon: 'el-icon-menu',
                component: () => import(/* webpackChunkName: "home" */ '../views/admin-admin'),
                name: '栏目管理',
                meta: {friendly: '栏目', model: 'Section', copy: true, keepAlive: false}
            },
            {
                path: '/admin/security-group',
                icon: 'el-icon-user-solid',
                component: () => import(/* webpackChunkName: "home" */ '../views/admin-admin'),
                name: '白名单管理',
                meta: {friendly: '白名单', model: 'SecurityGroup', copy: false, keepAlive: false}
            },
        ]
    },
    {
        path: '/',
        name: '评论和申请',
        icon: 'el-icon-chat-line-round',
        component: () => import(/* webpackChunkName: "home" */ '../views/home'),
        meta: {level: 3},
        children: [
            {
                path: '/admin/comment',
                component: () => import(/* webpackChunkName: "home" */ '../views/admin-comment'),
                name: '评论管理',
                meta: {friendly: '评论', model: 'Comment', no_add:true, edit: true, delete:false, keepAlive: false}
            },
            {
                path: '/admin/application',
                component: () => import(/* webpackChunkName: "home" */ '../views/admin-application'),
                name: '直播申请',
                meta: {friendly: '申请', model: 'ApplicationApproval', no_add:true, edit: true, delete:false, keepAlive: false}
            }
        ]
    },
    {
        path: '/',
        name: '报表管理',
        icon: 'el-icon-document',
        component: () => import(/* webpackChunkName: "home" */ '../views/home'),
        meta: {level: 3},
        children: [
            {
                path: '/admin/report/programs',
                icon: 'el-icon-data-analysis',
                component: () => import(/* webpackChunkName: "home" */ '../views/report-programs'),
                name: '节目统计',
                meta: {friendly: '节目', model: 'ReportProgram',no_delete:true,
                    no_edit: true, no_add:true, keepAlive: false}
            },
            {
                path: '/admin/report/statistics',
                icon: 'el-icon-data-line',
                component: () => import(/* webpackChunkName: "home" */ '../views/report-statistic'),
                name: '观看统计',
                meta: {friendly: '节目', model: 'ReportStatistics',no_delete:true,
                    no_edit: true, no_add:true, keepAlive: false}
                    },
            {
                path: '/admin/report/hours',
                icon: 'el-icon-pie-chart',
                component: () => import(/* webpackChunkName: "home" */ '../views/report-hours'),
                name: '观看时长',
                meta: {friendly: '节目', model: 'Access',no_delete:true,
                    no_edit: true, no_add:true, keepAlive: false}
            },
        ]
    },
    {
        path: '/',
        name: '后台管理',
        icon: 'el-icon-setting',
        component: () => import(/* webpackChunkName: "home" */ '../views/home'),
        meta: {level: 3},
        children: [
            {
                path: '/admin/wechat-notice',
                icon: 'el-icon-s-promotion',
                component: () => import(/* webpackChunkName: "home" */ '../views/admin-wechat-notice'),
                name: '微信通知',
                meta: {friendly: '通知', level: 4, model: 'WechatNotice'}
            },
            {
                path: '/admin/visitor',
                icon: 'el-icon-user',
                component: () => import(/* webpackChunkName: "home" */ '../views/admin-visitor'),
                name: '用户管理',
                meta: {friendly: '用户',level: 4, model: 'Visitor', keepAlive: false}
            },
            {
                path: '/admin/city',
                icon: 'el-icon-location',
                component: () => import(/* webpackChunkName: "home" */ '../views/admin-admin'),
                name: '城市管理',
                meta: {friendly: '城市', model: 'City', level: 4, copy: true, keepAlive: false}
            },
            {
                path: '/admin/department',
                icon: 'el-icon-office-building',
                component: () => import(/* webpackChunkName: "home" */ '../views/admin-admin'),
                name: '部门管理',
                meta: {friendly: '部门', model: 'Department', level: 4, copy: true, keepAlive: false}
            },
            {
                path: '/admin/stream',
                icon: 'el-icon-film',
                component: () => import(/* webpackChunkName: "home" */ '../views/admin-admin'),
                name: '流名管理',
                meta: {friendly: '流名', model: 'Stream',copy: true}
            },
            {
                path: '/admin/carousel',
                icon: 'el-icon-picture-outline',
                component: () => import(/* webpackChunkName: "home" */ '../views/admin-admin'),
                name: '幻灯片设置',
                meta: {friendly: '幻灯片', level: 4, model: 'Carousel'}
            },
            {
                path: '/admin/hot-program',
                icon: 'el-icon-picture',
                component: () => import(/* webpackChunkName: "home" */ '../views/admin-admin'),
                name: '推荐直播',
                meta: {friendly: '节目', level: 4, model: 'HotProgram'}
            },
        ]
    },
    {
        path: '/',
        name: '系统管理',
        icon: 'el-icon-cpu',
        component: () => import(/* webpackChunkName: "home" */ '../views/home'),
        meta: {level: 4},
        children: [
            {
                path: '/admin/parameter',
                icon: 'el-icon-s-operation',
                component: () => import(/* webpackChunkName: "home" */ '../views/admin-admin'),
                name: '参数设置',
                meta: {friendly: '参数', model: 'Parameter',copy: true}
            },
            {
                path: '/admin/syslog',
                icon: 'el-icon-key',
                component: () => import(/* webpackChunkName: "home" */ '../views/admin-syslog'),
                name: '系统日志',
                meta: {friendly: '日志',model: 'SysLog'}
            },
            {
                path: '/admin/ip-address',
                icon: 'el-icon-s-promotion',
                component: () => import(/* webpackChunkName: "home" */ '../views/admin-admin'),
                name: 'IP地址管理',
                meta: {friendly: 'IP地址', level: 4, model: 'InternalIps', copy: true, keepAlive: false}
            },
            {
                path: '/admin/edge-server',
                icon: 'el-icon-map-location',
                component: () => import(/* webpackChunkName: "home" */ '../views/admin-admin'),
                name: '媒体服务器',
                meta: {friendly: '媒体服务器', level: 4, model: 'EdgeServer',copy: true}
            },
        ]
    },


]
